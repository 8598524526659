import { createAction, props } from '@ngrx/store';
import { List } from 'src/app/core/models/app.models';

export const stepOneFormValid = createAction(
  '[Create List Effects] Step One Form Valid',
);
export const stepTwoFormValid = createAction(
  '[Create List Effects] Step Two Form Valid',
);
export const stepOneFormInvalid = createAction(
  '[Create List Effects] Step One Form Invalid',
);
export const stepTwoFormInvalid = createAction(
  '[Create List Effects] Step Two Form Invalid',
);
export const createListSuccessful = createAction(
  '[Create List Effects] Create List Successful',
  props<{
    listId: number;
  }>(),
);
export const createListUnsuccessful = createAction(
  '[Create Listt Effects] Create List Unsuccessful',
);
export const createListStarted = createAction(
  '[Create List Effects] Create List Started',
);
export const createListPostponed = createAction(
  '[Create List Effects] Create List Postponed',
);
