import { createFeature, createReducer, on } from '@ngrx/store';
import { CreateListState } from './create-list.state';
import { addDays, format } from 'date-fns';
import {
  MyEventsContainerActions,
  OrbitContainerActions,
} from '../../../core/store/actions';
import { CreateListContainerActions, CreateListEffectsActions } from './index';

import { mapEventToEventInformation } from '../../../core/helpers/mappers';

export const initialState: Readonly<CreateListState> = {
  currentStep: 1,
  list: {
    id: 0,
    name: '',
    maxInvitees: 0,
    price: '',
    namePromoter: '',
    emailPromoter: '',
    link: '',
    validTillDate: '',
    validTillTime: '',
    listType: null,
    event: null,
    listInvitee: [],
  },
  eventInformation: {
    name: '',
    startDate: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
    endDate: format(addDays(new Date(), 2), 'yyyy-MM-dd'),
    startTime: '',
    endTime: '',
    fromPrice: '',
    pressText: '',
    ticketLink: '',
    internalLabel: -1,
    ageRestrictionWomen: '',
    ageRestrictionMen: '',
    fyraTicketLink: '',
    location: 0,
    locationName: '',
    locationStreet: '',
    locationPlz: '',
    locationProvince: '',
    eventId: undefined,
    genres: [],
    tags: [],
    flyer: null,
  },
};

export const createListReducers = createReducer(
  initialState,
  on(OrbitContainerActions.createListClicked, (state, { event }) => ({
    ...state,
    currentStep: 1,
    eventInformation: mapEventToEventInformation(event),
  })),
  on(MyEventsContainerActions.editEventClicked, (state, { event }) => ({
    ...state,
    currentStep: 1,
    eventInformation: mapEventToEventInformation(event),
  })),
  on(CreateListContainerActions.listTypeClicked, (state) => ({
    ...state,
    currentStep: 2,
  })),
  on(CreateListContainerActions.lastStepClicked, (state) => ({
    ...state,
    currentStep: state.currentStep - 1,
  })),
  on(CreateListContainerActions.backClicked, (state) => ({
    ...state,
    currentStep: 1,
  })),
  on(CreateListEffectsActions.createListSuccessful, (state) => ({
    ...state,
    currentStep: 1,
  })),
);

export const createListFeature = createFeature({
  name: 'createList',
  reducer: createListReducers,
});
