import * as CreateListContainerActions from './actions/create-list-container.actions';
import * as CreateListEffectsActions from './actions/create-list-effects.actions';
import { createListFeature } from './create-list.reducers';

export * as createListEventState from './create-list.state';

const { reducer, name, ...createListSelectors } = createListFeature;

export {
  reducer as createListReducer,
  name as createListName,
  createListSelectors,
  CreateListContainerActions,
  CreateListEffectsActions,
};
