import { createAction, props } from '@ngrx/store';
import { Event } from 'src/app/core/models/app.models';

export const listTypeClicked = createAction(
  '[Create List Container] List Type Clicked',
);
export const nextButtonStepOneClicked = createAction(
  '[Create List Container] Next Button Step One Clicked',
);
export const nextButtonStepTwoClicked = createAction(
  '[Create List Container] Next Button Step Two Clicked',
);
export const createListClicked = createAction(
  '[Create List Container] Create List Clicked',
);
export const lastStepClicked = createAction(
  '[Create List Container] Last Step Clicked',
);
export const backClicked = createAction(
  '[Create List Container] Back Clicked',
  props<{
    event: Event;
  }>(),
);
